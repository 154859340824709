<template>
    <a-modal 
      v-model="visibleDecrease" 
      :title="$t('AgentCreditPointsPage.group_decrease')" 
      @ok="decreaseSubmit" 
      :width="600" 
      class="agents-credit-table"  
      :okText="$t('global.common.confirm')"
      :cancelText="$t('global.action.cancel')"
      :afterClose="inputDecreaseCancel"
    >
      <a-table
        royKey="account"
        :data-source="decreaseAgents"
        :columns="decreaseColumns"
        :pagination="false"
        :scroll="{ y: 600 }"
      >
        <template v-for="column in decreaseColumns">
          <template :slot="column.slots.title">
            {{ column.singleI18n ? $t(`AgentCreditPointsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}
            {{ (column.slots.title === 'agent_account' && decreaseAgents.length != 0 ? '(' + $t('AgentCreditPointsPage.has_selected_value', {value: decreaseAgents.length}) + ')' : '') }}
          </template>
        </template>
        <template slot="after_points">
          0
        </template>
      </a-table>
      <div>
        <p class="warning">
          * {{ isDecreaseErrorMsg ? $t(`AgentCreditPointsPage.confirm_agent_is_closed`) : $t(`AgentCreditPointsPage.confirm_all_agents_are_closed`)  }}
        </p>
      </div>
    </a-modal>
  </template>
  <script>
  import { 
    putPrepaidFundsPointsModify,
  } from '@/../api';
    const decreaseColumns = [
      {
        slots: { title: 'agent_account' },
        dataIndex: 'account',
        key: 'account',
        scopedSlots: { customRender: 'account' },
        align: 'center',
        singleI18n: false,
      },
      {
        slots: { title: 'current_points' },
        scopedSlots: { customRender: 'balance' },
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        singleI18n: true,
      },
      {
        slots: { title: 'points_after_change' },
        scopedSlots: { customRender: 'after_points' },
        dataIndex: 'after_points',
        key: 'after_points',
        align: 'center',
        singleI18n: true,
      },
    ];
    export default {
      props: {
        inputDecreaseAgents:{
          type: Array,
        },
        inputVisibleDecrease:{
          type: Boolean,
        },
        inputDecreaseCancel:{
          type: Function,
        },
        inputOnSearch:{
          type: Function,
        },
      },
      data() {
        return {
          decreaseAgents:[],
          decreaseColumns: decreaseColumns,
          visibleDecrease: false,
          isDecreaseErrorMsg: true,
        };
      },
      methods: {
        decreaseSubmit(){
          // console.log("decreaseSubmit",this.decreaseAgents);
          // 所有陣列中的狀態(active) 都是false時，才能下分
          const allInactive = this.decreaseAgents.every(obj => obj.active === false);
          if(allInactive){
            this.isDecreaseErrorMsg = true;
            const getIds = this.decreaseAgents.map(obj => obj.id);
            this.putAmountFetch(getIds, { agent_ids:getIds, type:'deduct', amount:0, is_batch: true });
          }
          else{
            this.isDecreaseErrorMsg = false;
          }
        },
        // 修改分數api
        async putAmountFetch(id, form){
          // console.log("putAmountFetch");
          await putPrepaidFundsPointsModify({
            id: id,
            form:{
              ...form
            }
          }).then(({ data }) => {
            this.$message.success(this.$t(`global.message.success_to_change`, { value: this.$t(`AgentCreditPointsPage.points`) }));
            this.inputDecreaseCancel();
            this.inputOnSearch();
          }).catch((error)=>{
            this.$message.error(this.$t(`global.message.failed_to_update`, { value: this.$t(`AgentCreditPointsPage.points`) }));
          });
        },
      },
      watch: {
        inputVisibleDecrease:{
          handler(newValue, oldValue){
            this.visibleDecrease = newValue;
          },
        },
        inputDecreaseAgents:{
            handler(newValue, oldValue){
              this.decreaseAgents = newValue;
            },
        },
      },  
    };
  </script>
    