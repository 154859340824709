<template>
  <div class="edit-agent">
    <a-button type="primary" icon="edit" @click="showModal">
      {{ $t('global.action.edit') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('AgentsPage.edit_agent')" :footer="null" :width="720">
      <a-form-model :layout="'vertical'" ref="editAgent" :model="editAgent" :rules="rules">
        <a-row :gutter="[16,16]">
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="editAgent.name" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.password')" prop="password">
              <a-input type="password" v-model="editAgent.password" />
            </a-form-model-item>
            <!-- <a-form-model-item :label="$t('global.common.email')" prop="email">
              <a-input v-model="editAgent.email" />
            </a-form-model-item> -->

            <template v-if="is_master_agent">
              <!-- Only Master Agent's curreny can be editor -->
              <a-form-model-item :label="$t('global.common.currency')" prop="currency">
                <a-select v-model="editAgent.currency">
                  <a-select-option v-for="currency in currencies" :key="currency" :value="currency">
                    {{ currency }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </template>

            <a-form-model-item :label="$t('global.common.business_status')" prop="status">
              <p class="remind-text" v-if="disabledActiveStatus">{{ $t('ProfilePage.disabled_change_status') }}</p>
              <a-select v-model="editAgent.status" :disabled="disabledActiveStatus">
                <a-select-option v-for="status in agent_status" :key="status" :value="status">
                  {{ $t(`global.common.active_status_list.${status}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item v-if="!is_master_agent" :label="$t('global.menu.AgentCreditPoints')" prop="prepaid_base_mode_status">
              <p class="remind-text" v-if="editAgent.status === 'active'">{{ $t('ProfilePage.change_only_agent_closed') }}</p>
              <a-select v-model="editAgent.prepaid_base_mode_status" :disabled="editAgent.status === 'active'">
                <a-select-option v-for="status in prepaid_base_mode_status_list" :key="status.value" :value="status.value">
                  {{ $t(`global.common.active_status_list.${status.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item v-if="!is_master_agent && editAgent.prepaid_base_mode_status" :label="$t('global.common.calculation_base')" prop="prepaid_base_mode">
              <p class="remind-text" v-if="editAgent.status === 'active'">{{ $t('ProfilePage.change_only_agent_closed') }}</p>
                <a-select v-model="editAgent.prepaid_base_mode" :disabled="editAgent.status === 'active'">
                  <a-select-option v-for="BasicCalculationItem in filterBasicCalculationList" :key="BasicCalculationItem.value" :value="BasicCalculationItem.value">
                    {{ $t(`global.common.${BasicCalculationItem.label}`) }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

            <!-- <a-form-model-item :label="$t('global.common.language')" prop="languages">
              <a-select v-model="editAgent.language">
                <a-select-option v-for="language in languages" :key="language.id" :value="language.id">
                  {{ language.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.theme')" prop="theme">
              <a-select v-model="editAgent.theme">
                <a-select-option v-for="themeOption in themeOptions" :key="themeOption" :value="themeOption">
                  {{ themeOption }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item 
              :label="$t('global.common.skin')" 
              prop="skin">
              <a-select 
              v-model="editAgent.skin">
                <a-select-option v-for="skinOption in skinOptions" :key="skinOption" :value="skinOption">
                  {{ $t(`global.common.skin_list.${skinOption}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.add_settle_bet_info')" prop="add_settle_bet_info">
              <a-select v-model="editAgent.add_settle_bet_info">
                <a-select-option v-for="status in multi_arena_status" :key="status" :value="status">
                  {{ $t(`global.common.multi_arena_status.${status}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.multi_arena')" prop="multi_arena">
              <a-select v-model="editAgent.multi_arena">
                <a-select-option v-for="status in multi_arena_status" :key="status" :value="status">
                  {{ $t(`global.common.multi_arena_status.${status}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <!-- 原本 v-if="is_merchant_account" 改成 false 是目前需求是要隱藏該欄位 -->
            <!-- <a-form-model-item :label="$t('AgentsPage.rate')" prop="rate" :extra="$t('AgentsPage.rate_note')" v-if="false">
              <a-row>
                <a-col :span="16">
                  <a-slider v-model="editAgent.rate" :min="rate_min" :max="rate_max" :step="0.1" />
                </a-col>
                <a-col :span="8">
                  <a-input-number
                    :value="editAgent.rate"
                    :min="rate_min"
                    :max="rate_max"
                    :step="0.1"
                    :formatter="value => `${value}%`"
                    :parser="parserValue"
                    @change="handleChangeRate"
                    style="marginLeft: 16px"
                  />
                </a-col>
              </a-row>
            </a-form-model-item> -->

            <!-- <a-form-model-item :label="$t('global.common.callback_url')" prop="callbackUrl" v-if="tradeMode === 'transfer'">
              <a-input v-model.trim="editAgent.callbackUrl" placeholder="https://..."/>
            </a-form-model-item>   

            <a-form-model-item 
              :label="$t('global.common.settle_zero')" 
              prop="settleZero">
              <a-select 
              v-model="editAgent.settleZero">
                <a-select-option v-for="(settleZeroItem, index) in settle_zero_list" :key="index" :value="settleZeroItem.value">
                  {{ $t(`global.common.active_status_list.${settleZeroItem.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->

          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" @click="editAgentSubmit(editAgent)" block>
            {{ $t('global.action.submit') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import numeral from 'numeral';
import { mapState } from 'vuex';

import {
  // getAgents,
  // getAgentById,
  getClientTheme,
  getClientCurrency,
  // getAgentProfile,
  updateAgentProfile,
  getCurrentManageRate
  // getLanguages
   } from '@/../api';
import { filterDuplicateData } from '@/../tools/common';

export default {
  props: {
    id: [String, Number],
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    const checkUrl = (rule, value, callback) => {
      try{
        new URL(value);
        callback();
      }catch(err){
        callback(new Error(this.$t('global.message.whole_url')));
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value.length < 6 && value.length > 0) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('ChangePasswordPage.new_password')})));
      } else {
        callback();
      }
    };
    const checkRate = (rule, value, callback) => {
      if (Number(value) > this.rate_max) {
        callback(new Error(this.$t('AgentsPage.max', {value: Number(this.rate_max).toFixed(1)})));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      // languages: [],
      agent_status: ['active', 'close'],
      multi_arena_status: ['open', 'close'],
      theme_list: [],
      settle_zero_list: [
        {
          label: 'active',
          value: 1,
        },
        {
          label: 'close',
          value: 0,
        },
      ],
      currencies: [],
      rate_max: 0, // This agent max rate
      rate_min: 0,
      is_master_agent: false,
      is_merchant_account: false,
      defaultBasicCalculationList: [
        {
          label: 'ggr',
          value: 'ggr'
        },
        {
          label: 'valid_amount',
          value: 'valid_amount'
        }
      ],
      merchant_prepaid_base_mode: null,
      prepaid_base_mode_status_list: [
        {
          label: 'active',
          value: 1,
        },
        {
          label: 'close',
          value: 0,
        }
      ],
      // tradeMode: '',
      last_prepaid_base_mode_status: 0,
      last_status: '',
      account: '',
      editAgent: {
        email: '',
        // name: '',
        theme: '',
        status: '',
        multi_arena: '',
        add_settle_bet_info: '',
        rate: 0,
        currency: '',
        password: '',
        // language: '',
        // callbackUrl: '',
        skin: '',
        // settleZero: 0,
        prepaid_base_mode: null,
        prepaid_base_mode_status: 0,
      },
      rules: {
        password: [{ validator: checkPassword, trigger: 'change' }],
        // name: [{ validator: checkName, trigger: 'change' }],
        theme: [{ required: true, trigger: 'change' }],
        skin: [{ required: true, trigger: 'change' }],
        currency: [{ required: true, trigger: 'change' }],
        status: [{ required: true, trigger: 'change' }],
        multi_arena: [{ required: true, trigger: 'change' }],
        add_settle_bet_info: [{ required: true, trigger: 'change' }],
        prepaid_base_mode_status: [{ required: true, trigger: 'change' }],
        prepaid_base_mode: [{ required: true, trigger: 'change' }],
        rate: [{ validator:checkRate, trigger: 'change' }],
        // callbackUrl: [{ validator: checkUrl, trigger: 'change' }],
      },
    };
  },
  computed: {
    ...mapState({currentProfile: state => state.auth.currentProfile}),
    themeOptions(){
      return this.theme_list.map(themeListItem=>themeListItem.name);
    },
    formTheme() {
      return this.editAgent.theme;
    },
    skinOptions() {
      const currentThemeOption = this.theme_list.find(themeItem=>themeItem.name === this.formTheme);
      return currentThemeOption ? currentThemeOption.skin_list : [];
    },
    formPrepaidBaseModeStatus() {
      return this.editAgent.prepaid_base_mode_status;
    },
    filterBasicCalculationList() {
      const prefix = this.currentProfile.prefix;
      switch(prefix){
        case 'ds88':
          return this.defaultBasicCalculationList.filter(basicCalculationItem=>basicCalculationItem.value === 'valid_amount');
        case 'ws168':
          return this.defaultBasicCalculationList.filter(basicCalculationItem=>basicCalculationItem.value === 'ggr');
        default:
          return this.defaultBasicCalculationList;
      }
    },
    disabledActiveStatus(){
      if(this.is_master_agent){
        return false;
      }else{
        return this.editAgent.prepaid_base_mode_status !== this.last_prepaid_base_mode_status
      }
    }
  },
  watch: {
    data: {
      handler(newValue){
        if(newValue){
          this.editAgent.theme = newValue.theme;
          this.editAgent.status = newValue.status;
          this.editAgent.multi_arena = newValue.multi_arena == true ? 'open' : 'close';
          this.editAgent.add_settle_bet_info = newValue.add_settle_bet_info == true ? 'open' : 'close';
          this.editAgent.currency = newValue.currency;
          this.editAgent.rate = numeral(newValue.rate).multiply(100).value();
          this.is_master_agent = newValue.is_master_agent;
          this.is_merchant_account = newValue.is_merchant_account;
          this.editAgent.name = newValue.name;
          this.editAgent.email = newValue.email;
          this.editAgent.skin = newValue.theme_color;
          this.editAgent.prepaid_base_mode = newValue.prepaid_base_mode ? newValue.prepaid_base_mode : this.filterBasicCalculationList[0].value;
          this.editAgent.prepaid_base_mode_status = newValue.prepaid_fund_active ? 1 : 0;
          this.last_prepaid_base_mode_status = newValue.prepaid_fund_active ? 1 : 0;
          this.merchant_prepaid_base_mode = newValue.merchant_prepaid_base_mode;
          this.account = newValue.account;
        }
      },
      immediate: true,
      deep: true
    },
    formTheme: {
      handler(newValue, oldValue){
        const currentTheme = this.theme_list.find(themeItem=>themeItem.name === newValue);
        if(currentTheme){
          if(oldValue !== '' || !this.editAgent.skin){
            this.editAgent.skin = currentTheme.skin_list[0];
          }
        }else{
          if(this.theme_list.length > 0){
            this.editAgent.theme = this.theme_list[0].name;
          }
        }
      }
    },
    formPrepaidBaseModeStatus: {
      handler(newValue, oldValue){
        if(newValue === 1 && this.editAgent.prepaid_base_mode === null){
          this.editAgent.prepaid_base_mode = this.merchant_prepaid_base_mode || this.defaultBasicCalculationList[0].value;
        }
      }
    }
  },
  methods: {
    init(){
      getClientTheme()
        .then((res)=>{
          this.theme_list = filterDuplicateData(res.data.themes,'theme');
        })
      // getLanguages()
      //   .then((data)=>{
      //     this.languages = data.data.languages;
      //   })

      getCurrentManageRate()
        .then((data) => {
          const { odds_mode, rate } = data.data;
          if(odds_mode !== 'rake'){
            this.rate_min = numeral(rate).multiply(100).value();
            this.rate_max = 100;
          }else{
            this.rate_min = 0;
            this.rate_max = numeral(rate).multiply(100).value();
          }
      })

      getClientCurrency()
        .then((data)=>{
          this.currencies =filterDuplicateData(data.data.currencies,'currency');
        })

      // getAgentProfileFindById({ id: this.id })
      //   .then((data)=>{
      //     this.editAgent.theme = data.data.theme;
      //     this.editAgent.status = data.data.status;
      //     this.editAgent.multi_arena = data.data.multi_arena == true ? 'open' : 'close';
      //     this.editAgent.add_settle_bet_info = data.data.add_settle_bet_info == true ? 'open' : 'close';
      //     this.editAgent.currency = data.data.currency;
      //     this.editAgent.rate = numeral(data.data.rate).multiply(100).value();
      //     this.is_master_agent = data.data.is_master_agent;
      //     this.is_merchant_account = data.data.is_merchant_account;
      //     this.editAgent.name = data.data.name;
      //     this.editAgent.email = data.data.email;
      //     this.editAgent.skin = data.data.theme_color;
      //     this.editAgent.prepaid_base_mode = data.data.prepaid_base_mode;
      //     this.editAgent.prepaid_base_mode_status = data.prepaid_fund_active ? 1 : 0;
      //     this.last_prepaid_base_mode_status = data.prepaid_fund_active ? 1 : 0;
      //     this.merchant_prepaid_base_mode = data.data.merchant_prepaid_base_mode;
      //     this.account = data.data.account;
      //     // this.tradeMode = data.data.trade_mode || 'credit';
      //     // this.editAgent.callbackUrl = data.data.swt_callback_url || '';
      //     // this.editAgent.settleZero = data.data.settle_zero || 0;
      //   })
      // getAgentProfile()
      //   .then((data)=>{
      //     this.editAgent.theme = data.data.theme;
      //     this.editAgent.status = data.data.status;
      //     this.editAgent.currency = data.data.currency;
      //     this.editAgent.rate = data.data.rate * 100;
      //     this.is_master_agent = data.data.is_master_agent;
      //   })
    },
    showModal() {
      this.visible = true;
    },
    parserValue(value) {
      const replacePercentValue = value.replace('%', '');
      const newValue = /[^\d.]/g.test(replacePercentValue) ? replacePercentValue.replace(/[^\d.]/g,'') : replacePercentValue ;
      const numberValue = newValue.split('.');
      const integerValue = numberValue[0];
      const decimalValue = numberValue[1];
      if(decimalValue && decimalValue.length > 3){
        return `${integerValue}.${decimalValue.substring(0, 3)}`;
      }else if(Number(newValue) >= this.rate_max){
        return String(this.rate_max);
      }else {
        return newValue;
      }
    },
    handleChangeRate(value){
      const newValue = String(value).length > 5 ? Number(String(value).slice(0,5)) : Number(value);
      this.editAgent.rate = newValue;
    },
    editAgentSubmit() {
      const { id } = this;
      const { currency, 
              // email, 
              name, 
              rate, 
              status,
              multi_arena, 
              add_settle_bet_info,
              theme, 
              password, 
              // callbackUrl, 
              skin, 
              // settleZero 
              prepaid_base_mode,
              prepaid_base_mode_status
              } = this.editAgent;
      // const settle_zero = settleZero === 1 ? true : false;
      const ratePercentage = rate / 100;
      this.$refs.editAgent.validate((valid) => {
        if (!valid) {
            // this.$message.error('error');
          return false;
          } else {
            const formData = {
              id, 
              currency, 
              // email, 
              name, 
              fee: ratePercentage, 
              active: status,
              multi_arena: multi_arena,
              add_settle_bet_info: add_settle_bet_info,
              theme: theme, 
              password, 
              theme_color: skin, 
              // swt_callback_url: callbackUrl,
              // settle_zero
              // language
            };

            if(!this.is_master_agent){
              formData.prepaid_base_mode = prepaid_base_mode ;
              formData.prepaid_fund_active = prepaid_base_mode_status === 1 ? true : false;
            }

            updateAgentProfile(formData)
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_change', {value: this.account}));
                this.visible = false;
                // if(this.role === 'merchant'){
                  this.$emit('update-data');
                // }
              })
              .catch((err) => {
                if(err.response){
                  const { error } = err.response.data;
                  if(error === 10803){
                    this.$message.error(this.$t('ProfilePage.please_turn_off_agent_status'))
                  }else if(error === 10804){
                    this.$message.error(this.$t('ProfilePage.please_decrease_points_to_zero'))
                  }else{
                    this.$message.error(this.$t('global.message.failed_to_update', {value: this.account}));
                  }
                }else{
                  this.$message.error(this.$t('global.message.failed_to_update', {value: this.account}));
                }
              });
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
