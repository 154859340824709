export default {
  lang: 'Português(葡萄牙文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: "Meron",
      wala: "Wala",
      draw: "Empate",
      up_down_draw: "Empate",
      cancel: "Cancelar",
      red: "Vermelho",
      blue: "Azul",
      gold: "Dourado",
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: "Confirmar execução",
      no: "Não executar",
      go: "Ir para",
      search: "Buscar",
      reset: "Redefinir",
      create: "Criar",
      logout: "Sair",
      submit: "Confirmar envio",
      edit: "Editar",
      // risk_edit: '', 
      lock_earning: "Pagamento",
      decision_on_hold: "Pausar partida",
      cancel_on_hold: "Iniciar partida",
      not_allow_bet: "Apostas não permitidas",
      allow_bet: "Permitir apostas",
      next: "Próximo passo",
      prev: "Passo anterior",
      step: "Passo",
      ok: "Confirmar",
      collapse: "Coletar",
      mask_video: "Bloqueio de vídeo",
      cancel_mask_video: "Fechar bloqueio de vídeo",
      records: "Registro",
      cancel: "Cancelar",
      export: "Exportação",
      select_file: "Selecionar arquivo",
      upload_image: "Upload de imagem",
      cancel_upload: "Cancelar upload",
      // update: '',
      update_all: "Atualizar tudo",
      upload_all_images: "Fazer upload de todas imagens",
      cancel_update_all: "Cancelar upload de todas imagens",
      refresh_fights: "Atualizar lutas",
      open_bet: "Aceitar apostas",
      close_bet: "Fechar apostas",
      re_declare: "Re-anunciar vencedor",
      locking: "Pagamento em andamento",
      open_arena: "Iniciar Arena para briga de galos",
      close_arena: "Fechar todas as rodadas sob a piscina",
      refresh_cache: "Limpar o cache",
      // cancel_all: '',
      delete: "Excluir",
      monitor: "Monitoramento",
      // monitor_video: '',
      // retry: '',
    },
    common: {
      account: "Conta",
      account_id: "Número",
      active_status_list: {
        all: "Todos",
        active: "Abrir",
        close: "Fechar",
        // enable: "",
        // disable: ""
      },
      action: "Ação",
      // add_settle_bet_info: '',
      agent: "Agente",
      agent_account: "Conta de agente",
      agent_count: "Número de agentes",
      arena: "Local",
      arena_suspend: "Arena para briga de galos pausada",
      all: "Todos",
      amount: "Valor",
      announcement: "Aviso",
      average_percentage: "Média de apostas por evento",
      bet_amount: "Valor apostado",
      bet_count: "Quantidade apostada",
      bet_type_list: {
        all: "Todos",
        deposit: "Depósito",
        withdraw: "Retirada",
        OrderDeposit: "Depósito",
        OrderWithdraw: "Retirada",
        bet: "Apostado",
        settle: "Liquidado",
        cancel: "Cancelar",
        resettle: "Re-liquidar",
        fail: "Falha",
        init: "Inicial"
      },
      // business_status: '',
      // calculation_base: '',
      callback_url: "Voltar URL",
      change_password: "Alterar senha",
      confirm: "Confirmar",
      confirm_password: "Confirmar senha",
      create_agent: "Adicionar agente",
      // create_setting: '',
      created_at: "Criado em",
      currency: "Moeda",
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: "Data",
      date_type: "Tipo de Horário",
      date_type_list: {
        BetAt: "Apostado em",
        GameDate: "Data do evento",
        SettleAt: "Hora de liquidação",
        OpenRound: "Hora de início"
      },
      declare_fight_winner: "O vencedor é anunciado como",
      defaultLanguage: "Idioma padrão",
      description: "Descrição",
      email: "E-mail",
      fight_count: "Número de eventos",
      fight_id: "Código do evento",
      game_date: "Data do evento",
      // game_type: '',
      // ggr: '',
      ip: "IP",
      id: "Número",
      kill_rate: "Taxa de comissão",
      language: "Idioma",
      last_login: "Último login",
      link_master: "Agente geral",
      lock_earning: "Pagamento",
      log: "Log",
      // main_arena: '',
      // main_pool: '',
      manager: "Gerenciar",
      master: "Agente mestre",
      max: "Máximo",
      merchant: "Empresa",
      merchant_account: "Conta da empresa",
      meron_wala: "Meron Wala",
      min: "Mínimo",
      month: "Mês",
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: "Nome",
      odd: "Probabilidades",
      odds_type: "Tipo de probabilidades",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: "Operador",
      // options: "",
      order_num: "Número do pedido",
      order_status_list: {
        init: "Recebido",
        process: "Processando",
        success: "Sucesso",
        failed: "Falha",
        pending: "Em espera",
        fail: "Falha",
        error: "Erro"
      },
      password: "Senha",
      payout: "Probabilidades",
      platform_profit: "Lucro da plataforma",
      player_account: "Conta do jogador",
      // player_name: "",
      player_bet_limit: "Limite de aposta única de jogador",
      player_count: "Número de jogadores",
      player_round_limit: "Limite de aposta por rodada de jogador",
      // pool_status: '',
      profile: "Informações do agente",
      provider_name: "Nome do provedor",
      rate: "Comissão (%)",
      rate_amount: "Total de comissões",
      risk_odd: "Probabilidades de risco",
      role: "Função",
      // risk_control: '',
      // risk_type_list:{
      //   bet_limit: '',
      // no_login: '',
      // },
      round_id: "Número do jogo",
      round_limit: "Limite de aposta por rodada",
      // round_status: '',
      round_status_list: {
        idle: "Inativo",
        open: "Apostas permitidas",
        close: "Apostas proibidas",
        locked: "Liquidação concluída",
        last_call: "Última aposta",
        arena_close: "Encerramento da arena",
        unsettled: 'Não Resolvido',
        settled: 'Resolvido',
      },
      status: "Status",
      // setting: '',
      settled_at: "Hora de liquidação",
      settled_zero: "Finalizar o pedido quando o valor for 0",
      side: "Apostado em",
      // skin: "",
      skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      },
      slug: "Número",
      theme: "Tema",
      total: "Total",
      total_bet_amount: "Total apostado",
      total_bet_count: "Número total de apostas",
      total_platform_profit: "Lucro total da plataforma",
      total_rate_amount: "Total de comissões",
      total_user_bet: "Número total de jogadores",
      total_valid_bet_amount: "Total de apostas válidas",
      type: "Tipo",
      // update_setting: '',
      updated_at: "Última atualização",
      usable_language: "Idiomas disponíveis",
      user: "Usuário",
      user_count: "Número de usuários",
      video_url: "URL da fonte de vídeo",
      // icp_video_url: "",
      valid_amount: "Aposta válida",
      winner: "Resultado"
    },
    times: {
      unset: "Não definido",
      today: "Hoje",
      yesterday: "Ontem",
      this_week: "Esta semana",
      last_week: "Semana passada",
      this_month: "Este mês",
      last_month: "Mês passado",
      custom: "Personalizado",
      tooltip: "Registros salvo: 3 meses (data de hoje - 100 dias)"
    },
    menu: {
      group: {
        player: "Jogador",
        record: "Relatório",
        manager: "Gerenciar",
        control: "Painel de Controle",
        Agent: "Gerenciamento de agente",
        MasterAgent: "Gerenciamento de agente geral",
        ArenaModerator: "Arena para briga de galos",
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: "Painel de Controle",
      CurrentArena: "Aposta atual",
      Players: "Gerenciamento de jogadores",
      PlayerCredits: "Registro de limite do jogador",
      BetLog: "Registro de apostas",
      GameResults: "Resultado do jogo",
      Order: "Registro de transferências",
      AgentList: "Lista de agentes",
      AgentSource: "Lista de agentes mestres",
      AdminAgents: "Gerenciamento de agente",
      AgentShow: "Informações do agente",
      AgentRoles: "Permissões do agente",
      AgentSubAccount: "Administrador",
      MasterAgentList: "Lista de agente geral",
      MasterAgentSource: "Lista de agentes mestres",
      Merchants: "Gerenciamento de empresa",
      MerchantRoles: "Gerenciamento de funções da empresa",
      MerchantAdmin: "Gerenciamento de contas da empresa",
      MerchantIpList: "Configurações de restrição de IP",
      ChangePassword: "Alterar senha",
      GGR: "Relatório GGR",
      Profile: "Dados pessoais",
      VideoSource: "Fonte de vídeo",
      Transfer: "Consultar ordens de transação",
      MonthlyProfit: "Resumo total de lucros mensais",
      DailyBettingStatistic: "Estatísticas diárias de todas as apostas",
      ExchangeRate: "Gerenciamento de taxas de câmbio",
      CreateAgent: "Adicionar agente",
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: "Por favor, digite {value}",
      select_value: "Por favor, selecione {value}",
      must_be_at_4_characters: "{value} requer no mínimo 4 caracteres",
      must_be_at_6_characters: "{value} requer no mínimo 6 caracteres",
      must_be_between_characters: "{value} deve ter entre {min} e {max} caracteres",
      must_be_between_number: "{value} deve estar entre {min} e {max}",
      must_be_alphanumeric: "{value} deve conter apenas letras e números",
      must_be_number: "Deve ser um número",
      must_be_number_value: "{value} deve ser um número",
      two_no_match: "O conteúdo dos dois campos de entrada é diferente",
      failed_to_create: "Falha ao criar {value}",
      failed_to_edit: "Falha ao editar {value}",
      success_to_create: "{value} criado com sucesso",
      success_to_edit: "{value} editado com sucesso",
      success_to_change: "{value} modificado com sucesso",
      failed_to_update: "{value} atualização falhou",
      decision_on_hold: "Ao clicar no botão acima, todas as apostas e transferências da Arena para briga de galos serão pausadas",
      cancel_on_hold: "Ao clicar no botão acima, o jogo da Arena para briga de galos será iniciado",
      close_bet: "Ao clicar no botão acima, as apostas na Arena para briga de galos serão pausadas",
      open_bet: "Ao clicar no botão acima, as apostas na Arena para briga de galos serão permitidas",
      mask_video: "Ao clicar no botão acima, a área de vídeo de GC será bloqueada",
      cancel_mask_video: "Ao clicar no botão acima, a área de vídeo bloqueada será fechada",
      delete: "Excluir {value}?",
      choose_arena: "Selecione a Arena para briga de galos",
      enter_fight_number: "Insira o número da partida",
      select_date: "Por favor, selecione uma data",
      select_one: "Por favor, selecione pelo menos um {value}",
      browser_not_supported: "Navegador não suportado.Clique no botão Confirmar para abrir o Google Chrome ou Safari.",
      network_error_title: "Erro de conexão",
      network_error_info: "Verifique sua conexão com a Internet e atualize a página",
      must_be_greater_than_zero: "O valor deve ser um número maior ou igual a 0",
      must_be_greater_than_min: "O valor máximo deve ser maior ou igual ao valor mínimo",
      incorrect_ip: "Formato de IP incorreto",
      criteria_be_selected: "Você deve selecionar um critério de pesquisa",
      success_to_delete: "{value} excluído com sucesso",
      failed_to_delete: "Falha ao excluir {value}",
      failed_to_delete_with_reason: "{value} atualização falhou: {reason}",
      account_placeholder: "Digite 4-16 caracteres alfanuméricos",
      password_placeholder: "Digite 6-20 caracteres alfanuméricos e símbolos",
      confirm_password_placeholder: "Digite a mesma nova senha novamente",
      date_type_hint: "Os relatórios são baseados em 「{dateType}」, 10:00(GMT+8) com estatísticas diárias das informações de ontem, sem incluir os dados de hoje.",
      please_create_role: "Antes de criar um administrador, crie uma função primeiro",
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: "Definir o valor máximo e mínimo que um jogador pode apostar em cada rodada",
      setting_player_bet_max: "Define o máximo que um jogador pode apostar de uma só vez",
      // A_greater_tham_B: '',
      // reset_data: '',
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    }
  },
  AgentsPage: {
    master_account: "Conta de agente mestre",
    languageList: "Lista de idiomas",
    create_master_agent: "Adicionar agente mestre",
    rate: "Comissão (%)",
    rate_note: "Se o subagente tiver uma taxa de comissão maior do que a do agente superior, o sistema ajustará automaticamente para a nova taxa de comissão",
    max_with_value: "Máxim: {value}",
    abbr: "Abreviação",
    abbr_placeholder: "Digite 1-6 caracteres alfanuméricos",
    edit_agent: "Editar agente"
  },
  AgentSubAccountPage: {
    authority: "Permissões",
    authority_list: {
      read: "Visualizar",
      editor: "Editar"
    },
    create_sub_account: "Adicionar administrador"
  },
  BetLogPage: {
    win_lose: "Resultado",
    bet_return: "Valor retornado",
    is_settled: "Liquidação concluída",
    device: "Dispositivo",
    amount_type: "Tipo de valor",
    amount_range: "Intervalo de valores"
  },
  ChangePasswordPage: {
    current_password: "Senha atual",
    current_password_placeholder: "Digite a senha atual",
    new_password: "Nova senha"
  },
  ArenaModeratorPage: {
    video_rate: "Comissão de vídeo",
    online_users: "Usuários online"
  },
  ControlPanelPage: {
    arena_on: "Abrir",
    arena_off: "Fechar",
    no_announce: "Nenhum aviso disponível, clique em editar para adicionar um",
    edit_announce: "Editar aviso",
    open_arena_title: "Iniciar operação de Arena para briga de galos?",
    close_arena_title: "Tem certeza de que deseja fechar a Arena para briga de galos de hoje?",
    start_fight: "Número do evento inicial",
    start_time: "Hora de início",
    open_betting: "Aceitar apostas?",
    close_betting: "Fechar apostas?",
    re_declare_title: "Re-anunciar vencedor?",
    re_declare_content: "Vencedor re-anunciado, número do evento {id}",
    lock_earing_title: "Pagamento?",
    lock_earing_content: "Pagamento efetuado, número do evento {id}",
    click_again_to: "Clique para",
    file_upload: "Enviar arquivo",
    arena_cover: "Capa de Arena",
    mask_file: "Bloqueio de Imagem",
    schedule: "Programação de eventos",
    edit_provider_url: "URL do vídeo",
    img_path: "Caminho do arquivo",
    below_px_value: "Abaixo de {value}px",
    img_format: "Formato",
    img_recommended_size: "Tamanho recomendado",
    file_size: "Tamanho do arquivo",
    image: "Imagem",
    file_no_selected: "Nenhum arquivo selecionado",
    no_image: "Nenhuma imagem disponível",
    edit_video_url: "Editar a fonte de vídeo",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: "Digite o número do evento inicial",
      max_image_size: "O tamanho máximo da imagem é {value} KB",
      error_image_type: "Formato de imagem incorreto, aceito apena: jpg, jpeg, png",
      delete_image_placeholder: "Tem certeza de que deseja excluir {value}?",
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    }
  },
  CurrentArenaPage: {
    count: "Quantidade",
    unsettlement: "Não liquidado",
    fight_action: "Ações de eventos"
  },
  GameResultsPage: {
    open_bet_at: "Início de apostas",
    close_bet_at: "Encerramento de apostas",
    declare_at: "Anúncio do resultado em",
    reload_at: "Reinício em",
    cancel_at: "Cancelamento em",
    total_rate: "Comissão total",
    total_meron: "Total apostado em Meron",
    total_wala: "Total apostado em Wala",
    total_draw: "Total apostado no Empate",
    status_list: {
      initial: "Inicial",
      betting: "Apostando",
      fighting: "Em combate",
      finished: "Liquidando",
      reloaded: "Reavaliação",
      earn_locked: "Pagamento",
      last_call: "Última aposta",
      cancel: "Cancelar"
    },
    time: "Hora",
    reason: "Motivo de cancelamento",
    cancel_reasons: {
      "3000": "Aposta única",
      "3001": "Odds não confirmados",
      canceled_manual: "Cancelamento manual",
      canceled_ws: "Cancelamento do sistema"
    },
    open_round: "Hora de início"
  },
  LoginPage: {
    log_in: "Entrar",
    otp: "Senha dinâmica",
    message: {
      success: "Login bem-sucedido!",
      failed: "Conta ou senha incorretos"
    }
  },
  MerchantsPage: {
    admin: "Gerenciamento de Administrador",
    role: "Gerenciamento de funções",
    create_merchant: "Adicionar empresa",
    name: "Nome da empresa",
    message: {
      master_agent_only_select_one: "Você só pode escolher entre um ou outro"
    }
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: "Páginas permitidas",
    use_account: "Conta de uso",
    create_role: "Criar função",
    edit_role: "Editar função",
    role_name: "Nome da função",
    color: "Cor",
    can_use_pages: "Páginas permitidas",
    arena_on_off: "Arena para briga de galos ligada/desligada",
    fight_control: "Controle de eventos",
    lock_earning: "Pagamento",
    announcement: "Aviso",
    decision_on_hold: "Interromper luta",
    monitor: "Monitoramento",
    no_bets_allowed: "Apostas não permitidas",
    mask_video: "Bloqueio de vídeo",
    read: "Visualizar",
    update: "Atualizar",
    create: "Criar",
    delete: "Excluir",
    edit: "Editar",
    dashboard_card: "Exibir quantia total",
    confirm: "Confirmar",
    approve: "Aprovar",
    arena_cover: "Capa de Arena",
    mask_file: "Bloqueio de Imagem",
    schedule: "Programação de eventos",
    export: "Exportação",
    edit_provider_url: "URL do vídeo",
    display_rate: "Exibir a comissão de casa %",
    // view_schedule: '',
    // view_log: '',
    account_to_use: "Ainda há contas usando esta função",
    // risk_edit: '',
    monitor_video: "Monitoramento de vídeo",
  },
  MerchantAdminPage: {
    edit_manager: "Editar administrador",
    create_manager: "Criar administrador"
  },
  MerchantIpPage: {
    master_agent: "Agente geral ou agente",
    create_ip: "Adicionar IP"
  },
  DailyBettingStatisticPage: {
    yesterday_percentage: "Comparação das apostas em relação a ontem %",
    last_week_percentage: "Comparação das apostas em relação à semana passada %",
    player_count: "Número de usuários",
    ave_per_fight_percentage: "Média por jogo em comparação com ontem %",
    last_week_ave_per_fight_percentage: "Média por jogo em comparação com a semana passada %"
  },
  OrderPage: {
    after_balance: "Saldo após transferência"
  },
  PlayersPage: {
    balance: "Saldo",
    fee: "Taxa",
    current_sign_in_ip: "IP de Login",
    total_deposit: "Total depositado",
    total_withdraw: "Total retirado",
    total_win_lose: "Total de lucro/perda"
  },
  PlayerCreditsPage: {
    id: "Número de transação",
    type: {
      deposit: "Depósito",
      withdraw: "Retirada",
      settle: "Vitória",
      cancel: "Cancelar",
      bet: "Apostado"
    },
    before_amount: "Saldo antes da operação",
    after_amount: "Saldo após a operação"
  },
  ProfilePage: {
    // setting_round_draw_max: "Definir o limite de aposta para cada rodada",
    setting_round_draw_max_part1: "A cada rodada",
    setting_round_draw_max_part2: "Quantidade limite de apostas",
    player_limit: "Limite de apostas de jogador",
    setting_player_max: "Definir o valor máximo e mínimo que um jogador pode apostar em cada rodada",
    basic_info_title: "Informações básicas",
    basic_info_text: "Informações básicas sobre seu agente",
    // integration_info: "",
    // auth: "",
    // secret: "",
    // api_doc_url: "",
    // api_url: "",
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
  },
  VideoSourcePage: {
    source: "Fonte de vídeo",
    agent_source: "Fontes de vídeo do agente mestre",
    accessable_merchants: "Empresas disponíveis"
  },
  TransferPage: {
    slug: "Número de transação",
    data: "Dados",
    res: "Resposta",
    round_id: "Rodada",
    setting_master_id: "Configurações",
    num: "Número",
    resend: "Reenviar",
    bet_num: "Número",
    resend_note: "Observação",
    resend_mark: "Marcação",
    message: {
      success_to_resend: "Reenvio com sucesso, No: {value}",
      fail_to_resend: "Falha ao reenviar, No: {value}",
      success_to_remark: "Comentário adicionado com sucesso, No: {value}",
      fail_to_remark: "Falha ao adicionar comentário, No: {value}"
    }
  },
  ExchangeRatePage: {
    currency: "ID da moeda",
    currency_name: "Nome da moeda",
    last_approved_rate: "Taxa de câmbio anterior",
    today_rate: "Taxa de câmbio atual",
    ref_rate: "Taxa de câmbio de referência",
    ref_rate_time: "Hora da taxa de câmbio de referência",
    tbd_confirm_rate: "Confirmar taxa de câmbio",
    tbd_modify_rate: "Modificar taxa de câmbio",
    record: "Registro de alterações",
    current_time: "Hora atual",
    last_approved_time: "Hora da aprovação anterior",
    base_currency: "Moeda base",
    fetch_exchange_rate: "Obter taxa de câmbio",
    approve_rate: "Aprovar taxa de câmbio",
    rate_warning: "*Atençã: A taxa de câmbio será efetivada imediatamente após a aprovação!",
    log: "Registro de alterações da taxa de câmbio",
    confirmed_by: "Responsável pela confirmação",
    confirmed_time: "Hora de confirmação",
    approved_by: "Responsável pela aprovação",
    approved_time: "Hora de aprovação",
    exchange_rate: "Taxa de câmbio"
  },
  CreateAgentPage: {
    parent_agent: "Agente superior",
    environment: "Ambiente",
    staging: "Site de testes",
    production: "Site oficial",
    brand: "Marca",
    trade_mode: "Modo de transação",
    transfer_wallet: "Transferência de carteira",
    single_wallet: "Carteira única",
    // options: '',
    level_sub_agent_with_value: "Agente nível {value}",
    delete_agent_with_value: "Excluir agente de nível {value}",
    add_agent_with_value: "Adicionar agente de nível {value}",
    agent_info: "Informações do agente",
    message: {
      select_master_first: "Por favor, selecione primeiro um agente",
      agent_same_master: "O agente de nível superior é o mesmo que o agente principal",
      please_refresh_cache: "Por favor, atualize o cache para obter a nova lista de agentes",
      log_saved_time: "Cada registro é mantido por até {value} horas",
      success_refresh_cache: "Cache atualizado com sucesso",
      fail_refresh_cache: "A Atualização do cache falhou"
    }
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // current_points: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: ''
  },
}
