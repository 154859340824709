export default {
  lang: 'हिन्दी(印度文)',
  global: {
    game_type: {
      cockfight: "कॉकफाइट",
      spider: "स्पाइडर",
      color_game: "कलर_गेम",
      apple: "एप्पल",
      // up_down: '',
    },
    fight_result: {
      meron: "मीरॉन",
      wala: "वाला",
      draw: "ड्रॉ",
      up_down_draw: "ड्रॉ",
      cancel: "कैंसिल करें",
      red: 'लाल',
      blue: 'नीला',
      gold: 'गोल्ड',
      white: "सफ़ेद",
      white3: "सफ़ेद*3",
      red3: "लाल*3",
      // down: '',
      // up: '',
    },
    action: {
      yes: "हाँ",
      no: "नहीं",
      go: "जाएं",
      search: "ढूंढें",
      reset: "रीसेट करें",
      create: "बनाएं",
      logout: "लॉग आउट करें",
      submit: "सबमिट करें",
      edit: "एडिट करें",
      // risk_edit: "",
      lock_earning: "कमाई को लॉक करें",
      decision_on_hold: "निर्णय होल्ड पर",
      cancel_on_hold: "कैंसिल करना होल्ड पर",
      not_allow_bet: "किसी बेट की अनुमति नहीं है",
      allow_bet: "बेट की अनुमति है",
      next: "अगला",
      prev: "पिछला",
      step: "स्टेप",
      ok: "ठीक है",
      collapse: "छोटा करें",
      mask_video: "वीडियो मास्क करें",
      cancel_mask_video: "वीडियो मास्क करना कैंसिल करें",
      records: "रिकॉर्ड्स",
      cancel: "कैंसिल करें",
      export: "एक्सपोर्ट करें",
      select_file: "फाइल चुनें",
      upload_image: "अपलोड करें",
      cancel_upload: "कैंसिल करें",
      update: "अपडेट करें",
      update_all: "सभी अपडेट करें",
      upload_all_images: "सभी अपलोड करें",
      cancel_update_all: "सब कैंसिल करें",
      refresh_fights: "फाइट्स को रिफ्रेश करें",
      open_bet: "खोलें",
      close_bet: "बंद करें",
      re_declare: "दोबारा घोषणा करें",
      locking: "लॉक किया जा रहा है",
      open_arena: "एरेना खोलें",
      close_arena: "पूल के तहत सभी राउंड बंद करें",
      refresh_cache: "कैश रिफ्रेश करें",
      cancel_all: "सब कैंसिल करें",
      // delete: ""
      monitor: "मॉनिटर",
      // monitor_video: '',
      // retry: '',
    },
    common: {
      account: "अकाउंट",
      account_id: "नंबर",
      active_status_list: {
        all: "सभी",
        active: "एक्टिविटी",
        close: "बंद करें",
        enable: "चालू करें",
        disable: "असमर्थ"
      },
      action: "एक्शन",
      // add_settle_bet_info: '',
      agent: "एजेंट",
      agent_account: "एजेंट अकाउंट",
      agent_count: "एजेंट काउंट्स",
      arena: "एरेना",
      arena_suspend: "एरेना सस्पेंड",
      all: "सभी",
      amount: "अमाउंट",
      announcement: "घोषणा",
      average_percentage: "प्रति फाइट का औसत",
      bet_amount: "बेट अमाउंट",
      bet_count: "बेट काउंट्स",
      bet_type_list: {
        all: "सभी",
        deposit: "डिपॉज़िट",
        withdraw: "विदड्रॉ करें",
        OrderDeposit: "डिपॉज़िट",
        OrderWithdraw: "विदड्रॉ करें",
        bet: "बेट",
        settle: "सेटल",
        cancel: "कैंसिल करें",
        resettle: "रिसेटल",
        fail: "नहीं हो पाया",
        init: "शुरुआती"
      },
      // business_status: '',
      // calculation_base: '',
      callback_url: "कॉलबैक यूआरएल",
      change_password: "पासवर्ड बदलें",
      confirm: "कन्फर्म करें",
      confirm_password: "पासवर्ड कन्फर्म करें",
      create_agent: "एजेंट बनाएं",
      create_setting: "सेटिंग बनाएं",
      created_at: "पर बनाया गया",
      currency: "करेंसी",
      custom_logo: "कस्टम लोगो",
      custom_logo_list: {
        // odin: "ओडिन",
        // gleague: "ओडिन"
        // fiesta: '',
        // fiestafruit: '',
      },
      date: "तारीख",
      date_type: "तारीख का प्रकार",
      date_type_list: {
        BetAt: "पर बेट किया लगाया",
        GameDate: "खेल की तारीख",
        SettleAt: "पर सेटल करें",
        OpenRound: "ओपन राउंड"
      },
      declare_fight_winner: "फाइट का विजेता घोषित करें",
      defaultLanguage: "डिफ़ॉल्ट भाषा",
      description: "ब्यौरा",
      email: "ईमेल",
      fight_count: "फाइट काउंट्स",
      fight_id: "फाइट आईडी",
      game_date: "खेल की तारीख",
      game_type: "गेम किस तरह का है",
      // ggr: '',
      ip: "आईपी",
      id: "नंबर",
      kill_rate: "किल रेट",
      language: "भाषा",
      last_login: "पिछला लॉगिन",
      link_master: "लिंक मास्टर",
      lock_earning: "कमाई को लॉक करें",
      log: "लॉग",
      // main_arena: '',
      // main_pool: '',
      manager: "मैनेजर",
      master: "मास्टर",
      max: "अधिकतम",
      merchant: "मर्चेंट",
      merchant_account: "मर्चेंट आइडी",
      meron_wala: "मीरॉन वाला",
      min: "न्यूनतम",
      month: "महीना",
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: "नाम",
      odd: "ऑड",
      odds_type: "ऑड्स टाइप",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: "ऑपरेटर",
      options: "विकल्प",
      order_num: "ऑर्डर नंबर",
      order_status_list: {
        init: "इनिट",
        process: "प्रोसेस",
        success: "सही से हो गया",
        failed: "नहीं हो पाया",
        pending: "अभी पूरा नहीं हुआ",
        fail: "नहीं हो पाया",
        error: "गड़बड़ी"
      },
      password: "पासवर्ड",
      payout: "पेआउट",
      platform_profit: "जीजीआर",
      player_account: "प्लेयर का अकाउंट",
      // player_name: "",
      player_bet_limit: "प्लेयर की सिंगल बेट लिमिट",
      player_count: "प्लेयर काउंट्स",
      player_round_limit: "प्लेयर की राउंड बेट लिमिट",
      // pool_status: '',
      profile: "प्रोफाइल",
      provider_name: "प्रोवाइडर का नाम",
      rate: "रेट (%)",
      rate_amount: "रेट अमाउंट",
      risk_odd: "रिस्क ऑड्स",
      role: "रोल",
      // risk_control: "",
      risk_type_list: {
        // bet_limit: "",
        // no_login: '',
      },
      round_id: "फाइट #",
      round_limit: "राउंड बेट की लिमिट",
      // round_status: '',
      round_status_list: {
        idle: "खाली",
        open: "खोलें",
        close: "बंद करें",
        locked: "लॉक किया हुआ",
        last_call: "आखिरी कॉल",
        arena_close: "एरेना बंद",
        unsettled: 'अनसुलझा',
        settled: 'सुलझा हुआ',
      },
      status: "स्टेटस",
      setting: "सेटिंग",
      settled_at: "पर सेटल करें",
      settle_zero: "जब अमाउंट 0 हो तो सेटल ऑर्डर जनरेट करें",
      side: "बेट ऑन",
      skin: "थीम कलर",
      skin_list: {
        default: "डिफ़ॉल्ट",
        brown: "भूरा",
        white: "सफ़ेद",
        black: "काला",
        // green: "",
        dark_and_light: "डार्क और लाइट"
      },
      slug: "स्लग",
      theme: "थीम",
      total: "टोटल",
      total_bet_amount: "टोटल बेट अमाउंट",
      total_bet_count: "टोटल बेट काउंट्स",
      total_platform_profit: "टोटल प्लेटफ़ॉर्म प्रॉफिट",
      total_rate_amount: "टोटल रेट अमाउंट",
      total_user_bet: "टोटल यूज़र बेट्स",
      total_valid_bet_amount: "टोटल वैलिड बेट अमाउंट",
      type: "किस तरह का है",
      updated_at: "पर अपडेट किया गया",
      updated_setting: "अपडेट सेटिंग",
      usable_language: "प्रयोग करने योग्य भाषा",
      user: "यूज़र",
      user_count: "यूज़र काउंट",
      video_url: "वीडियो यूआरएल",
      // icp_video_url: "",
      valid_amount: "वैलिड अमाउंट",
      winner: "विजेता"
    },
    times: {
      unset: "अनसेट",
      today: "आज",
      yesterday: "गुज़रा हुआ कल",
      this_week: "इस सप्ताह",
      last_week: "पिछले सप्ताह",
      this_month: "इस महीने",
      last_month: "पिछले महीने",
      custom: "कस्टम",
      tooltip: "रिकॉर्ड रिटेंशन: 3 महीने (आज की तारीख - 100 दिन)"
    },
    menu: {
      group: {
        player: "प्लेयर",
        record: "रिकॉर्ड्स",
        manager: "मैनेजर",
        control: "कंट्रोल पैनल",
        Agent: "एजेंट",
        MasterAgent: "मास्टर एजेंट",
        ArenaModerator: "एरेना मॉडरेटर",
        Pool: "पूल"
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: "कंट्रोल पैनल",
      CurrentArena: "मौजूदा एरेना",
      Players: "प्लेयर्स",
      PlayerCredits: "प्लेयर क्रेडिट्स",
      BetLog: "बेट लॉग",
      GameResults: "गेम रिज़ल्ट्स",
      Order: "ऑर्डर",
      AgentList: "एजेंट लिस्ट",
      AgentSource: "मास्टर एजेंट लिंक",
      AdminAgents: "एजेंट्स",
      AgentShow: "एजेंट शो",
      AgentRoles: "एजेंट रोल्स",
      AgentSubAccount: "मैनेजर",
      MasterAgentList: "मास्टर एजेंट लिस्ट",
      MasterAgentSource: "मास्टर एजेंट लिंक",
      Merchants: "मर्चेंट्स",
      MerchantRoles: "मर्चेंट रोल",
      MerchantAdmin: "मर्चेंट एडमिन",
      MerchantIpList: "आईपी प्रतिबंध सेटिंग्स",
      ChangePassword: "पासवर्ड बदलें",
      GGR: "जीजीआर",
      Profile: "प्रोफाइल",
      VideoSource: "वीडियो सोर्स",
      Transfer: "ऑर्डर इन्क्वायरी",
      MonthlyProfit: "मंथली प्रॉफिट",
      DailyBettingStatistic: "डेली बेटिंग स्टेटिस्टिक्स",
      ExchangeRate: "एक्सचेंज रेट",
      CreateAgent: "एजेंट बनाएं",
      PoolSetting: "पूल सेटिंग",
      MalayOddsSetting: "मलय ऑड्स सेटिंग",
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: "कृपया {value} एंटर करें!",
      select_value: "कृपया a(n) {value} चुनें।",
      must_be_at_4_characters: "{value} कम से कम 4 कैरेक्टर की होनी चाहिए",
      must_be_at_6_characters: "{value} कम से कम 6 कैरेक्टर की होनी चाहिए",
      must_be_between_characters: "{value} {min} से {max} कैरेक्टर की होनी चाहिए",
      must_be_between_number: "{value} {min} से {max} के बीच होनी चाहिए",
      must_be_alphanumeric: "{value} अल्फान्यूमेरिक होनी चाहिए",
      must_be_number: "संख्या होनी चाहिए",
      must_be_number_value: "{value} संख्या होनी चाहिए",
      two_no_match: "दो इनपुट मेल नहीं खाते!",
      failed_to_create: "{value} नहीं बना पाए",
      failed_to_edit: "{value} नहीं बदल पाए",
      success_to_create: "{value} सही से बन गई",
      success_to_edit: "{value} सही से बदल दी गई",
      success_to_change: "{value} सही से मॉडिफाइ कर दी गई",
      failed_to_update: "{value} अपडेट नहीं कर पाए",
      decision_on_hold: "क्लिक करने पर, एरेना होल्ड पर हो जाएगा।",
      cancel_on_hold: "क्लिक करने पर, एरेना स्टार्ट हो जाएगा।",
      close_bet: "क्लिक करने पर, एरेना बेट क्लोज़ कर देगा।",
      open_bet: "क्लिक करने पर, एरेना बेट ओपन कर देगा।",
      mask_video: "क्लिक करने पर, GC वीडियो मास्क हो जाएगा।",
      cancel_mask_video: "क्लिक करने पर, GC वीडियो स्टार्ट हो जाएगा।",
      delete: "{value} मिटाएं?",
      choose_arena: "एरेना चुनें",
      enter_fight_number: "स्टार्ट फाइट नंबर एंटर करें",
      select_date: "कृपया एक तारीख चुनें",
      select_one: "कृपया कम से कम एक {value} चुनें",
      browser_not_supported: "यह ब्राउज़र सपोर्टेड नहीं है। गूगल क्रोम या सफारी खोलने के लिए कृपया ओके बटन पर क्लिक करें।",
      network_error_title: "नेटवर्क में खराबी",
      network_error_info: "कृपया अपना इंटरनेट जांचें और पेज को रिफ्रेश करें",
      must_be_greater_than_zero: "अमाउंट 0 से अधिक या उसके बराबर होना चाहिए",
      must_be_greater_than_min: "अधिकतम अमाउंट न्यूनतम अमाउंट से अधिक या उसके बराबर होना चाहिए",
      incorrect_ip: "गलत आईपी फॉर्मेट",
      criteria_be_selected: "एक खोज क्राइटेरिया चुना जाना चाहिए",
      success_to_delete: "{value} सफलतापूर्वक मिटा दिया गया",
      failed_to_delete: "{value} मिटाया नहीं जा सका",
      failed_to_delete_with_reason: "{value} अपडेट नहीं किया जा सका: {reason}",
      account_placeholder: "4-16 कैरेक्टर, केवल अल्फ़ान्यूमेरिक।",
      password_placeholder: "6-20 कैरेक्टर",
      confirm_password_placeholder: "कृपया वही पासवर्ड दोबारा डालें",
      date_type_hint: "रिपोर्ट「{dateType}」 पर आधारित है। आज के डेटा को छोड़कर, बीते कल का डेटा हर दिन 10:00 (जीएमटी+8) पर जनरेट\n किया जाएगा।",
      please_create_role: "मैनेजर बनाने से पहले, कृपया एक रोल बनाएँ।",
      max_length: "{value}\\ की अधिकतम लंबाई {length} है",
      whole_url: "कृपया पूरा URL डालें।",
      A_greater_tham_B: "{Value_a} को {value_b} से बड़ा होना चाहिए",
      setting_player_round_max: "न्यूनतम और अधिकतम अमाउंट सेट करें",
      setting_player_bet_max: "अधिकतम अमाउंट सेट करें",
      // reset_data: ""
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    }
  },
  AgentsPage: {
    master_account: "मास्टर एजेंट आईडी",
    languageList: "भाषा सूची",
    create_master_agent: "मास्टर एजेंट बनाएं",
    rate: "कमीशन रेट (%)",
    rate_note: "यदि डाउनलाइन एजेंट का कमीशन रेट अपलाइन एजेंट के कमीशन रेट से अधिक है, तो सिस्टम ऑटोमेटिकली इसे घटाकर एक नया कमीशन रेट कर देगा।",
    max_with_value: "अधिकतम: {value}",
    abbr: "संक्षिप्त रूप में",
    abbr_placeholder: "1-6 कैरेक्टर",
    edit_agent: "एजेंट को एडिट करें",
  },
  AgentSubAccountPage: {
    authority: "अथॉरिटी",
    authority_list: {
      read: "पढ़ें",
      editor: "एडिटर"
    },
    create_sub_account: "मैनेजर बनाएं"
  },
  BetLogPage: {
    win_lose: "जीत हार",
    bet_return: "बेट रिटर्न",
    is_settled: "सेटल किया गया है",
    device: "डिवाइस",
    amount_type: "अमाउंट प्रकार",
    amount_range: "अमाउंट सीमा"
  },
  ChangePasswordPage: {
    current_password: "मौजूदा पासवर्ड",
    current_password_placeholder: "कृपया मौजूदा पासवर्ड डालें",
    new_password: "नया पासवर्ड"
  },
  ArenaModeratorPage: {
    video_rate: "वीडियो रेट",
    online_users: "ऑनलाइन उपयोगकर्ता"
  },
  ControlPanelPage: {
    arena_on: "ऑन",
    arena_off: "ऑफ",
    no_announce: "कोई घोषणा नहीं, अपडेट करने के लिए कृपया \"एडिट करें\" पर क्लिक करें",
    edit_announce: "घोषणा को एडिट करें",
    open_arena_title: "एरेना खोलें?",
    close_arena_title: "क्या आप वाकई एरेना बंद करना चाहते हैं?",
    start_fight: "फाइट आईडी शुरू करें",
    start_time: "स्टार्ट टाइम",
    open_betting: "बेटिंग शुरू करें?",
    close_betting: "बेटिंग बंद करें?",
    re_declare_title: "दोबारा घोषणा करें?",
    re_declare_content: "फाइट आईडी {id} विनर की दोबारा घोषणा करें",
    lock_earing_title: "कमाई को लॉक करें?",
    lock_earing_content: "अर्निंग फाइट आईडी {id} को लॉक करें",
    click_again_to: "के लिए फिर से क्लिक करें",
    file_upload: "फ़ाइल अपलोड करें",
    arena_cover: "एरेना कवर",
    mask_file: "फ़ाइल मास्क करें",
    schedule: "शेड्यूल",
    edit_provider_url: "प्रोवाइडर यूआरएल",
    img_path: "इमेज पाथ",
    below_px_value: "{value}px से कम",
    img_format: "फॉर्मेट",
    img_recommended_size: "सुझावित साइज़",
    file_size: "फ़ाइल का साइज़",
    image: "इमेज",
    file_no_selected: "कोई फ़ाइल नहीं चुनी गई",
    no_image: "कोई इमेज नहीं",
    edit_video_url: "वीडियो यूआरएल को एडिट करें",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: "कृपया फाइट नंबर डालें",
      max_image_size: "इमेज का अधिकतम साइज़ {value} kb है।",
      error_image_type: "एरर इमेज टाइप. केवल ये स्वीकार किए जाते हैं: jpg, jpeg, png",
      delete_image_placeholder: "क्या आप वाकई {value} को हटाना चाहते हैं?",
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    }
  },
  CurrentArenaPage: {
    count: "काउंट्स",
    unsettlement: "अनसेटलमेंट",
    fight_action: "फाइट एक्शन",
  },
  GameResultsPage: {
    open_bet_at: "पर बेटिंग शुरू करें",
    close_bet_at: "पर बेटिंग बंद करें",
    declare_at: "पर घोषित करें",
    reload_at: "पर रिलोड करें",
    cancel_at: "पर कैंसिल करें",
    total_rate: "टोटल रेट",
    total_meron: "टोटल मीरॉन",
    total_wala: "टोटल वाला",
    total_draw: "टोटल ड्रा",
    status_list: {
      initial: "शुरुआती",
      betting: "बेटिंग",
      fighting: "फाइटिंग",
      finished: "पूरा हुआ",
      reloaded: "रिलोड किया गया",
      earn_locked: "सेटल किया गया",
      last_call: "आखिरी कॉल",
      cancel: "कैंसिल करें"
    },
    time: "टाइम",
    reason: "कारण",
    cancel_reasons: {
      '3000': "एकतरफा बेट",
      '3001': "ऑड्स अनुपलब्ध",
      canceled_manual: "फाइट कैंसिल की गई-मैनुअल",
      canceled_ws: "फाइट कैंसिल की गई-WS"
    },
    open_round: "ओपन राउंड"
  },
  LoginPage: {
    log_in: "लॉग इन",
    otp: "ओटीपी",
    message: {
      success: "लॉग इन सफल!",
      failed: "अकाउंट या पासवर्ड एंटर नहीं हो सका।"
    }
  },
  MerchantsPage: {
    admin: "एडमिन",
    role: "रोल",
    create_merchant: "मर्चेंट बनाएँ",
    name: "मर्चेंट का नाम",
    message: {
      master_agent_only_select_one: "मास्टर/एजेंट में से केवल एक विकल्प ही चुना जा सकता है।"
    }
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: "पेज",
    use_account: "एकाउंट्स इस्तेमाल करें",
    create_role: "रोल बनाएँ",
    edit_role: "रोल को एडिट करें",
    role_name: "रोल का नाम",
    color: "रंग",
    can_use_pages: "एक्सेस किए जा सकने वाले पेज",
    arena_on_off: "एरेना ऑन/ऑफ़",
    fight_control: "फाइट कंट्रोल",
    lock_earning: "कमाई को लॉक करें",
    announcement: "घोषणा",
    decision_on_hold: "निर्णय होल्ड पर",
    monitor: "मॉनिटर",
    no_bets_allowed: "किसी बेट की अनुमति नहीं है",
    mask_video: "वीडियो मास्क करें",
    read: "पढ़ें",
    update: "अपडेट करें",
    create: "बनाएं",
    delete: "हटाएं",
    edit: "एडिट करें",
    dashboard_card: "टोटल दिखाएं",
    confirm: "कन्फर्म करें",
    approve: "एप्रूव करें",
    arena_cover: "एरेना कवर",
    mask_file: "फ़ाइल मास्क करें",
    schedule: "शेड्यूल",
    export: "एक्सपोर्ट करें",
    edit_provider_url: "प्रोवाइडर यूआरएल",
    display_rate: "डिस्प्ले रेट",
    // view_schedule: '',
    // view_log: '',
    account_to_use: "इस रोल का उपयोग करने के लिए अभी भी एक अकाउंट है",
    // risk_edit: ""
    monitor_video: "वीडियो मॉनिटर",
  },
  MerchantAdminPage: {
    edit_manager: "मैनेजर को एडिट करें",
    create_manager: "मैनेजर बनाएं"
  },
  MerchantIpPage: {
    master_agent: "मास्टर या एजेंट",
    create_ip: "आईपी बनाएं"
  },
  DailyBettingStatisticPage: {
    yesterday_percentage: "कल का टीवीए %",
    last_week_percentage: "पिछले सप्ताह का टीवीए %",
    player_count: "यूज़र काउंट",
    ave_per_fight_percentage: "कल का एपीएफ %",
    last_week_ave_per_fight_percentage: "पिछले सप्ताह का एपीएफ %"
  },
  OrderPage: {
    after_balance: "बैलेंस के बाद"
  },
  PlayersPage: {
    balance: "बैलेंस",
    fee: "शुल्क",
    current_sign_in_ip: "वर्तमान साइन इन आईपी",
    total_deposit: "टोटल डिपॉज़िट",
    total_withdraw: "टोटल विदड्रॉ",
    total_win_lose: "टोटल जीत हार"
  },
  PlayerCreditsPage: {
    id: "आईडी",
    type: {
      deposit: "डिपॉज़िट",
      withdraw: "विदड्रॉ करें",
      settle: "ऑर्डर सेटल करें",
      cancel: "ऑर्डर कैंसिल करें",
      bet: "बेट"
    },
    before_amount: "अमाउंट से पहले",
    after_amount: "अमाउंट के बाद"
  },
  ProfilePage: {
    setting_round_draw_max: "1 राउंड ड्रॉ का अधिकतम अमाउंट सेट किया जा रहा है",
    setting_round_draw_max_part1: "की अधिकतम मात्रा सेट करें",
    setting_round_draw_max_part2: "1 राउंड में",
    player_limit: "प्लेयर बेट की लिमिट",
    setting_player_max: "न्यूनतम और अधिकतम अमाउंट सेट करें",
    basic_info_title: "आम जानकारी",
    basic_info_text: "आपके एजेंट के बारे में आम जानकारी",
    integration_info: "इंटीग्रेशन की जानकारी",
    auth: "ऑथॉराइज़ेशन",
    secret: "सीक्रेट की (Key)",
    api_doc_url: "एपीआई डॉक्यूमेंट यूआरएल",
    api_url: "एपीआई यूआरएल",
    // video_traffic: "",
    // remain_video_data: "",
    // update_video_data: "",
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
  },
  VideoSourcePage: {
    source: "वीडियो सोर्स",
    agent_source: "मास्टर एजेंट वीडियो सोर्स",
    accessable_merchants: "एक्सेस किए जा सकने वाले मर्चेंट"
  },
  TransferPage: {
    slug: "स्लग",
    data: "डेटा",
    res: "रिस्पोंस",
    round_id: "राउंड आईडी",
    setting_master_id: "मास्टर आईडी सेट करना",
    num: "नंबर",
    resend: "दोबारा भेजें",
    bet_num: "बेट नंबर",
    resend_note: "नोट",
    resend_mark: "मार्क",
    message: {
      success_to_resend: "दोबारा सही से भेज दिया गया, No:{value}",
      fail_to_resend: "दोबारा भेजा नहीं जा सका, No:{value}",
      success_to_remark: "रिमार्क सही से किया गया, No:{value}",
      fail_to_remark: "रिमार्क नहीं किया नहीं जा सका, No:{value}"
    }
  },
  ExchangeRatePage: {
    currency: "करेंसी",
    currency_name: "करेंसी का नाम",
    last_approved_rate: "पिछला रेट",
    today_rate: "आज का रेट",
    ref_rate: "रेफ. रेट",
    ref_rate_time: "रेफ. टाइम",
    tbd_confirm_rate: "रेट कन्फर्म करें",
    tbd_modify_rate: "रेट मॉडिफाई करें",
    record: "रिकॉर्ड",
    current_time: "मौजूदा टाइम",
    last_approved_time: "पिछला एप्रूव किया गया टाइम",
    base_currency: "बेस करेंसी",
    fetch_exchange_rate: "एक्सचेंज रेट फेच करें",
    approve_rate: "रेट एप्रूव करें",
    rate_warning: "* ध्यान दें: एक्सचेंज रेट एप्रूव होने के तुरंत बाद लागू होगा!",
    log: "एक्सचेंज रेट लॉग",
    confirmed_by: "द्वारा कन्फर्म किया गया",
    confirmed_time: "इस टाइम पर कन्फर्म किया गया",
    approved_by: "द्वारा एप्रूव किया गया",
    approved_time: "एप्रूव किया गया टाइम",
    exchange_rate: "एक्सचेंज रेट"
  },
  CreateAgentPage: {
    parent_agent: "पैरेंट एजेंट",
    environment: "एनवायरनमेंट",
    staging: "स्टेजिंग",
    production: "प्रोडक्शन",
    brand: "ब्रैंड",
    trade_mode: "ट्रेड मोड",
    transfer_wallet: "ट्रांसफर वॉलेट",
    single_wallet: "सिंगल वॉलेट",
    // options: '',
    level_sub_agent_with_value: "स्तर {value} सब-एजेंट",
    delete_agent_with_value: "स्तर {value} सब-एजेंट हटाएं",
    add_agent_with_value: "स्तर {value} सब-एजेंट जोड़ें",
    agent_info: "एजेंट की जानकारी",
    message: {
      select_master_first: "कृपया पहले एक मास्टर एजेंट चुनें।",
      agent_same_master: "पैरेंट एजेंट, मास्टर के जैसा ही होता है।",
      please_refresh_cache: "नई एजेंट लिस्ट प्राप्त करने के लिए कृपया कैश मेमोरी को रीफ्रेश करें।",
      log_saved_time: "प्रत्येक लॉग को अधिकतम {value} घंटों तक सेव रखा जाएगा।",
      success_refresh_cache: "कैश मेमोरी को रीफ्रेश किया गया।",
      fail_refresh_cache: "कैश मेमोरी को रीफ्रेश नहीं किया जा सका।"
    }
  },
  PoolSetting: {
    pool_name: "पूल का नाम",
    odds_refresh_frequency: "रिफ्रेश फ्रीक्वेंसी",
    last_call_percentage: "पिछली कॉल (%)",
    close_bet_percentage: "बेट बंद करें(%)",
    per_bet: "प्रति बेट",
    per_round: "प्रति राउंड",
    risk_threshold: "रिस्क थ्रेशोल्ड",
    // current_schedule: "",
    // new_schedule: "",
    // update_schedule: "",
    // schedule: "",
    // view: "",
    // start_time: "",
    // end_time: "",
    // pool_configuration_options: "",
    // expect_date: "",
    // expect_time: "",
    // add_new_pool_configuration: "",
    // exceed_time: "",
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // current_points: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: ''
  },
}
